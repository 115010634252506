import * as React from "react";
import DemoButtons from "./Utiilities/DemoButtons";
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from "./Utiilities/ImageSlider";
import Plans from "./Utiilities/Plans";
import Accordion from "./Utiilities/AccordionItem";
import Lightbox from "./Utiilities/Lightbox";
import SpecailButtons from "./Utiilities/SpecialButtons";
import Footer from "./Header and Footer/Footer";
import Header from "./Header and Footer/Header";
import ScrollToTop from "./Utiilities/ScrolltoTop";
import G2Badge from "./Utiilities/G2Badge";
import TabsWithImage from "./Utiilities/TabsWithImage";
import Availability from "./Utiilities/Availability";
import OurClients from "./Utiilities/OurClients";
import Integration from "./Utiilities/Integration";
import { MyContext } from "../App";

function PM365() {
  const AppName = React.useContext(MyContext)
  const tabs = [
    { id: 1, title: 'Appraisals', Heading: `Simplify appraisals using ${AppName} Performance Management Software`, content: (
      <ul>
        <li>
        Efficiently manage employee evaluations with our performance management software.
        </li>
        <li>
        Customize appraisal cycles and rating scales for Microsoft Performance Management to align with your goals.
        </li>
        <li>
        Apps365 provides a collaborative review environment for enhanced accuracy and efficiency.
        </li>
      
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Integration_J10FfNdpZB.gif?updatedAt=1720500232501' },
    { id: 2, title: 'Feedback', Heading: 'Comprehensive 360-Degree Feedback for Ongoing Improvement', content:  (
      <ul>
        <li>
        HR performance management software enables tracking of employee progress and facilitates the exchange of constructive and productive feedback among peers, managers, and other stakeholders.
        </li>
        <li>
        This creates a continuous feedback loop within the appraisal cycle and supports spontaneous ad hoc feedback scenarios.
        </li>
       
      </ul>
    ),  image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Searching_bszxfaq2js.gif?updatedAt=1720500232508'},
    { id: 3, title: 'Visualization', Heading: 'Interactive Organizational Visualizations', content: `The SharePoint Employee Directory includes dynamic and visually appealing organizational charts. These interactive charts serve as a visual guide, offering instant clarity on the company's hierarchy, team configurations, and intricate reporting lines. This feature is indispensable for helping team members understand their place within the organization and enables managers to strategically design and optimize team structures. By simplifying complex organizational relationships into clear, accessible visuals, these charts enhance collaboration and support more effective strategic planning and decision-making.`, image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/visualization_viJFIn6Wt.gif?updatedAt=1720500231624' },
    {
        id: 4, title: 'Data Synchronization', Heading: 'Real-Time Data Synchronization: Employee Directory Software', content: `SharePoint Employee Directory Software guarantees accurate and consistent employee data through real-time synchronization with Microsoft 365. Any updates or changes within Microsoft 365 are immediately reflected in the directory, thanks to this dynamic integration. This real-time syncing ensures that records remain up-to-date and free of discrepancies, offering a reliable framework for managing organizational data efficiently`,
        image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/dataSync__gvNUPNCn.gif?updatedAt=1720500232251'
    },
    {
        id: 5, title: 'Security', Heading: 'Enhanced Confidentiality and Security: Employee Directory 365 ', content: (
          <ul>
              <li>Employee Directory 365 stands as a bastion of privacy with its advanced security measures and rigorous controls.</li>
              <li>It safeguards sensitive employee data by adhering strictly to legal and organizational standards, all within the Microsoft 365 framework.</li>
              <li>This software ensures that your workforce’s data stays secure within your Microsoft 365 tenant, protecting against unauthorized access and maintaining stringent compliance.</li>
              <li>This robust defense ensures that critical information about your employees remains protected and confined to your organization.</li>
          </ul>
      ),
        image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Security%20(1)_9wJphPYCa.gif?updatedAt=1720500232285'
    },
];
  const planData = [
    {
      name: "Standard",
      price: "$79",
      sup: "99",
      text: "Standard Plan Features",
      textY: "per month, billed yearly",
      features: [
        "10 users",
        "2 support tickets per annum",
        "Free updates via MS store",
        "Integrates with Office 365",
        "SharePoint app only",
        "Data stays in SharePoint",
        "Multiple roles",
      ],
    },
    {
      name: "Plus",
      price: "$149",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Standard and...",
      features: [
        "25 users",
        "4 support tickets per annum",
        "Free updates with support",
        "KRA Categories",
        "Custom Logo",
        "5 Point rating scale",
        "Notifications",
        "Multiple departments & groups",
        "Multiple appraisal cycles",
      ],
    },
    {
      name: "Premium",
      price: "$249",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Plus, and...",
      features: [
        "40 users",
        "Additional users at $1.5/user",
        "Unlimited support tickets",
        "Microsoft Teams App",
        "Self-Review",
        "KRA bank",
        "KRA templates",
        "Weighted rating option",
        "Dashboard - Performance Analytics",
        "Rating to verbatims",
        "360 degree feedback module",
        "1:1 meeting tracker Module",
        "Smart notifications",
      ],
    },
    {
      name: "Enterprise",
      price: "$329",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Premium and...",
      features: [
        "50 Users",
        "Additional users at $2/user",
        "Multiple Reviewers",
        "9 point enterprise rating scale",
        "Multiple rating scales options",
        "Collaboration timelines",
        "Dashboard - Org analytics",
        "1:1 Meetings on Calendar",
        "Customizable email notifications",
        "Customize with Power Automate",
        "Power BI & Power Apps Integration",
        "Dedicated Account Manager",
        "Integration with KRAs",
        "One free customization*",
      ],
    },
  ];
  const accordionItems = [
    {
      title: `1. What is an employee performance tracker, and why is it important?`,
      content:
        `An employee performance tracker is a tool within performance management software that monitors and records employee progress against set goals and objectives. It is crucial for identifying strengths, areas for improvement, and ensuring that employees are aligned with company goals.`,
    },
    {
      title: `2. How do I choose the best performance management software for my business?`,
      content: `To choose the best performance management software, consider factors like ease of use, scalability, integration capabilities, and customer support. It should align with your business needs and help in achieving your performance management goals effectively.`,
    },
    {
      title:
        `3. Can performance management software integrate with our existing HR systems?`,
      content:
       `Yes, most performance management software is designed to integrate seamlessly with existing HR systems, including payroll, HRIS, and learning management systems. This integration ensures a unified approach to managing employee performance across the organization.`,
    },
    {
      title: `4. Can I install multiple instances of Employee Performance Management 365?`,
      content: `Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.`,
    },
    {
      title: `5. How ${AppName}'s Performance Management System differentiate?`,
      content: `Employee Performance Management Tools or employee feedback app enable clients to conduct a multi-dimensional assessment of their employees’ abilities, behavioural competencies, and performance. The analytics system will provide managers with the data, expertise, and tools they need to conduct a more constructive evaluation of their team.`,
    }
  ];
  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
       <ScrollToTop/>
    <Header/>
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">
              Employee Performance Management Software
            </h1>
            <p className="HR_textclr">
            Performance Management 365 is a premier appraisal tool designed for visionary organizations. It delivers real-time reviews with sophisticated monitoring, analysis, and evaluation, ensuring your goals and ROI are seamlessly achieved.
            </p>
            
          </div>
          <div className="HR_Section1RightSide">
            <img alt="MainImage" src={ImageSction1} loading="lazy" />
          </div>
        </div>
      </div>
      <div>
                <G2Badge
                 heading="Now Performance Management 365 App is Available on Microsoft Teams"
                 imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/PM365_nLChfGaEs.png?updatedAt=1720507129448"  />
            </div>
            <div id="features" className="IdChanges"></div>
            <div >
            <TabsWithImage tabs={tabs}/>
        </div>
        <div>
                <Availability/>
            </div>
            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection' >
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>

                <div className='HR_MT'>
                    <OurClients />
                </div>
            </div>
            <div id="integration" className="IdChangesIntegration"></div>
            <div className='integartionPT'>
           <Integration appName={"Performance Management 365"} />

           </div>
   
           <div id="pricing" className="IdChangesIntegration"></div>
            <h3 className='HR_heading HR_MT' >Performance Management 365 Plans
            </h3>
     

      <div className="HR_whitesection">
      <div className="HR_MT">
          <div>
            <Plans plans={planData} />
          </div>
        </div>
       
        <SpecailButtons />
        <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ' >

                <div>
                    <h4 className='HR_faq'>Frequently Asked Questions</h4>
                    <Accordion items={accordionItems} />
                </div>


            </div>
   <Footer/> </>
  );
}
export default PM365;
