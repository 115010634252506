import * as React from "react";
import DemoButtons from "./Utiilities/DemoButtons";
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import Plans from "./Utiilities/Plans";
import Accordion from "./Utiilities/AccordionItem";
import SpecailButtons from "./Utiilities/SpecialButtons";
import Header from "./Header and Footer/Header";
import Footer from "./Header and Footer/Footer";
import ScrollToTop from "./Utiilities/ScrolltoTop";
import G2Badge from "./Utiilities/G2Badge";
import TabsWithImage from "./Utiilities/TabsWithImage";
import Availability from "./Utiilities/Availability";
import OurClients from "./Utiilities/OurClients";
import Integration from "./Utiilities/Integration";
import { MyContext } from "../App";

function HD365() {
  const AppName = React.useContext(MyContext);
  const planData = [
    {
      name: "Standard",
      price: "$11",
      sup: "99",
      text: "Standard Plan Features",
      textY: "per month, billed yearly",
      features: [
        "Minimum 5 users",
        "25 ticket requesters",
        "2 Support tickets per year",
        "Free updates via Microsoft store",
        "Integrates with Office 365",
        "SharePoint-hosted app",
        "Data stays in your SharePoint",
        "Custom request types",
        "Mobile responsive",
        "Multiple roles",
        "Smart notifications",
        "Self Service web forms",
        "Multiple departments groups",
        "Multiple departments groups",
        "Collaboration with ticket requester",
      ],
    },
    {
      name: "Plus",
      price: "$24",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Standard and...",
      features: [
        "Minimum 5 users",
        "50 ticket requesters",
        "Unlimited support tickets",
        "Commonly users service fields",
        "Dashboard",
        "Canned responses",
        "Custom signatures",
        "Custom ticket status",
        "Custom ticket sequence",
        "Ticket fields customization",
        "Private Notes",
        "Ticket Splitting & Merging",
      ],
    },
    {
      name: "Premium",
      price: "$44",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Plus, and...",
      features: [
        "Minimum 5 users",
        "Unlimited ticket requesters",
        "Unlimited support",
        "Customer satisfaction Surveys",
        "Free updates with support",
        "Create new ticket from emails",
        "Email templates",
        "Auto assign tickets",
        "Auto close tickets",
        "Auto capture email attachments",
        "Integrate with Asset Management",
        "Block spammers and domains",
        "Onelevel sub services",
        "Custom views",
        "Custom columns",
      ],
    },
    {
      name: "Enterprise",
      price: "$64",
      sup: "99",
      textY: "per month, billed yearly",
      text: "Everything in Premium and...",
      features: [
        "Minimum 5 users ",
        "Dedicated account manager",
        "Kanban View",
        "Escalate Level 1 & Level 2",
        "Multiple SLA choices",
        "Create Sub tickets​",
        "Service and sub-service fields",
        "Auto escalate tickets",
        "Ticket due date reminders​",
        "Ticket aging report",
        "Knowledge - base",
        "Approval workflows",
        "Custom forms",
        "Multiple mailboxes department wise",
        "Rules & automation",
        "Archive",
        "Create KB from tickets",
        "Create ticket through MS Forms",
        "Customize with Power Automate",
        "API connectivity",
        "Connect with Power BI",
        "Available in 10 Language",
        "One free customization*",
        "Multilevel sub service*",
      ],
    },
  ];
  const data = [
    {
      heading: "Respond quickly and consistently",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/TicketDetails_HD365_M4XwPCWlv.png?updatedAt=1685598825749",
    },
    {
      p: "View, reply and repeat. With canned actions we’ve made this really easy",
      heading: "Connect Helpdesk 365 with your other apps",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/powerplatformtoolset_ReRdfzh4N.png?updatedAt=1700466299384",
    },
    {
      heading: "Collaborate with your teammates",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Comments_HD365_x7hBhTdyM.png?updatedAt=1685503315168",
    },
    {
      heading: "Simple & Easy ticketing system",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Settings_HD365_4vXqK4vRw_.png?updatedAt=1685422911172",
    },
    {
      heading: "Intra-department Support",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Homepage_HD365_noFv1A00F.png?updatedAt=1685424223912",
    },
    {
      heading: "Find & reward your stars ",
      imageUrl:
        "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Customer_Survey_HD365_3Qei9O4HT.png?updatedAt=1685588116513",
    },

    // Add more objects as needed
  ];

  const accordionItems = [
    {
      title: "1. Can an IT Ticketing System be customized to fit specific IT processes?",
      content: `Yes, many IT Ticketing Systems offer customization options such as custom workflows, ticket categories, and priority levels. This allows organizations to tailor the system to match their specific IT processes and requirements.`,
    },
    {
      title: "2. How does a SharePoint ticketing system work?",
      content: `A SharePoint ticketing system uses SharePoint's lists, libraries, and workflows to create, manage, and track helpdesk tickets. Users submit requests through forms or email, which are then captured as tickets in SharePoint. The system allows for ticket assignment, tracking, and resolution within the SharePoint environment.`,
    },
    {
      title: "3. Is Helpdesk Software suitable for all types of businesses?",
      content:
        `Yes, Helpdesk Software can be adapted to various industries and business sizes, from small businesses to large enterprises. It is designed to handle different types of support requests and can scale according to the organization’s needs.`,
    },
    {
      title: "4. How do you calculate user license?",
      content: `Any individual who is working on tickets, responding to the tickets, managing tickets, or administering the helpdesk i.e. agents, supervisors, and admins are counted towards user license. All the plans come with five user licenses, you can buy more user licenses by contacting the sales team or connecting with support team.

All the requesters who are raising the requests for themselves, are defined as requesters, number of requesters are defined in each plan. You can get unlimited requesters in Premium and Enterprise plans.`
    },
    {
      title: "5. Can I install multiple instances of Helpdesk 365?",
      content: `Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.`,
    },
    {
      title: `6. Can I install Helpdesk 365 on multiple site collections or run two or more instances.`,
      content: `Yes, you can install Helpdesk 365 on multiple site collections or teams if user license count remains within the purchased license count. Incase you wish to install Helpdesk 365 on different office 365 tenant, you need to buy another license.`,
    },
    {
      title: `Can I migrate Helpdesk 365 from one tenant to another Office 365 tenant.`,
      content: `Yes, you can migrate Helpdesk 365 from one to anther tenant, please contact support team to complete the request.`,
    },
    {
      title: `What are available languages in the Helpdesk 365 application?`,
      content: `The available languages are Czech, Dutch, English, French, German, Hungarian, Italian, Japanese, Polish, Portuguese, Russian, and Spanish.`,
    },
  ];
  // const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  // const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

  // const openLightbox = (imageUrl) => {
  //   setSelectedImageUrl(imageUrl);
  //   setLightboxOpen(true);
  // };

  // const closeLightbox = () => {
  //   setLightboxOpen(false);
  // };
  const tabs1 = [
    { id: 1, title: 'Focus on what you need', Heading: 'Focus on what ‘s necessary: SharePoint IT Ticketing System', content: (<ul>
      <li>
      Revolutionize workflow management with Apps365’s cloud-based helpdesk ticketing system. It organizes tickets by priority, category, time, and status, streamlining processes.
      </li>
      <li>
      The SharePoint portal allows easy request submission via email or an embedded web part, while integration with Microsoft Teams provides conversational support and seamless collaboration.
      </li>
      <li>
      This system enhances request tracking, boosting productivity and customer satisfaction across IT, HR, and more.
      </li>
    </ul>), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Recording4-ezgif.com-video-to-gif-converter_1t1BQO-ac.gif?updatedAt=1718713728955' },
    { id: 2, title: 'Ticket Management', Heading: ' Comprehensive Ticket Management Features', content:(
      <ul>
        Expand your IT Service Management capabilities with Helpdesk 365’s advanced features:
        <li>
        This system enhances request tracking, boosting productivity and customer satisfaction across IT, HR, and more.
        </li>
        <li>
        Joint Ticket Management
        </li>
        <li>
        Accurate Time Logging
        </li>
        <li>
        Versatile Ticket Templates
        </li>
        <li>
        Custom Field Options
        </li>
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/TicketManagement_NawCmGYkO.gif?updatedAt=1719903031399' },
    { id: 3, title: 'SLA on cloud-based ', Heading: 'Enhance Operational Efficiency with SLAs in our Cloud-Based HR Ticketing System', content: (
      <ul>
        <li>
        Experience improved service delivery with Apps365’s cloud-based IT ticketing system, backed by robust SLAs. Our platform ensures reliable service and customer satisfaction.
        </li>
        <li>
        With detailed reporting and analytics in SharePoint, Apps365 monitors performance against SLA benchmarks and continuously optimizes support processes to enhance service quality.
        </li>
        <li>
        Automated SLA tracking and alerting features help ensure timely response and resolution of IT issues.
        </li>
        <li>
        Customizable SLA templates allow for tailored service levels to meet specific organizational needs and requirements.
        </li>
      </ul>

    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/SLA_SKQ5cy1rQf.gif?updatedAt=1719814962251' },
    {
      id: 4, title: 'Helpdesk to your needs', Heading: 'Customize Your SharePoint Helpdesk for Seamless Operations', content: (
        <ul>
          <li>
          Enhance your support experience with our versatile Microsoft IT ticketing system, integrated with SharePoint.
          </li>
          <li>
          Efficiently distribute tickets, prioritize tasks, and streamline notifications.
          </li>
          <li>
          Our helpdesk software boosts productivity, optimizes workflows, and improves response times, creating a supportive environment that exceeds expectations.
          </li>
          <li>
          Advanced reporting and analytics tools provide insights into ticket trends and team performance for continuous improvement.
          </li>
          <li>
          Integration with Microsoft Teams enables real-time collaboration and communication among support staff for faster issue resolution.
          </li>
        </ul>
      ),
      image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/approve_Q5cb5xjOH.gif?updatedAt=1719898618060'
    },
   
  ];
  const tabs2 = [
    { id: 1, title: ' Support Experience ', Heading: 'Transform Your Support with Cutting-edge Microsoft Teams Ticketing System Features', content: (
      <ul>
        <li>
        Empower customer support with our comprehensive ticketing system, boosting productivity.
        </li>
        <li>
        Clients can easily request tickets and get instant status updates through our intuitive platform.
        </li>
        <li>
        The SharePoint ticketing system not only tracks tickets but also helps identify solutions, with quick access to historical data and a knowledge base, accelerating issue resolution and reducing new ticket submissions.
        </li>
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Supportexp_eht3Bt82e6.gif?updatedAt=1719903031166' },
    { id: 2, title: 'Security Measures', Heading: ' Enhanced Security for Identity and Access Management', content: (
      <ul>
        <li>
        SharePoint help desk ticketing system ensures security with robust identity and access management. Integrated with Microsoft 365, it employs a defense-in-depth strategy with multiple security layers.
        </li>
        <li>
        These measures, along with stringent practices, protect your Microsoft ticketing system against threats and unauthorized access. For detailed insights into our security protocols, contact Apps365.
        </li>
        <li>
        Regular security updates and vulnerability assessments ensure ongoing protection and adaptation to emerging threats.
        </li>
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Securitymeasures_SLoTGlIE5.gif?updatedAt=1719903031258' },
    { id: 3, title: 'Boost Productivity', Heading: 'Transform Customer Support and Drive Operational Excellence with IT & HR Helpdesk', content: (
      <ul>
        <li>
        Helpdesk software enhances customer service by providing insightful reports to gauge satisfaction and optimize team performance.
        </li>
        <li>
        It uncovers actionable insights for immediate improvements, ensuring a seamless experience for both teams and clients.
        </li>
        <li>
        Automated ticketing and workflow management streamline support processes, reducing response times and increasing efficiency.
        </li>
        <li>
        Customizable dashboards and metrics allow for tailored performance tracking and reporting to meet specific business needs.
        </li>
      </ul>
    ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/boast_xwE5iohIU.gif?updatedAt=1719904751386' },
    {
      id: 4, title: 'Rules and Automation', Heading: 'Drive Efficiency with Customized Rules and Automation', content: (
        <ul>
          <li>
          Boost operational efficiency with Apps365’s 'Rule & Automation' feature. Customize and automate ticket management by creating advanced rules for generating, assigning, and routing tickets based on your needs.
          </li>
          <li>
          This feature streamlines ITSM operations, ensures prompt attention to every ticket, and saves valuable time and resources.
          </li>
          <li>
          Automated escalation protocols ensure critical issues receive immediate attention, reducing resolution times for high-priority tickets.
          </li>
          <li>
          Flexible rule configuration allows for dynamic adjustments to workflows, adapting to changing operational requirements and priorities.
          </li>
        </ul>
      ),
      image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Rules&automation_qYBfGojFe.gif?updatedAt=1719903031561'
    },
   
  ];
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">
            Helpdesk Software – SharePoint Helpdesk 365
            </h1>
            <p className="HR_textclr">
            Elevate customer service with Helpdesk 365. Boost efficiency, enhance communication, and deliver unmatched support for superior customer satisfaction.
            </p>
           
          </div>
          <div className="HR_Section1RightSide">
            <img alt="MainImage" src={ImageSction1} loading="lazy" />
          </div>
        </div>
      </div>
      <div>
        <G2Badge
          heading="Now Helpdesk 365 App is Available on Microsoft Teams"
          imageUrl="https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/HD365%20Teams_bGn7Gudo4J.png?updatedAt=1701258105168" />
      </div>
      <div id="features" className="IdChanges"></div>
      <div >
        <TabsWithImage tabs={tabs1} />
      </div>
      <div  >
        <TabsWithImage tabs={tabs2} />
      </div>
      <div>
                <Availability/>
            </div>
            <div id="client" className="IdChanges"></div>
            <div className='HR_whitesection' >
                <h2 className='HR_heading HR_MT' >Our Clients
                </h2>

                <div className='HR_MT'>
                    <OurClients />
                </div>
            </div>
            <div id="integration" className="IdChangesIntegration"></div>
            <div  className='integartionPT'>
           <Integration appName={"Helpdesk 365"} />

           </div>
           <div id="pricing" className="IdChanges"></div>
      <div className="HR_whitesection">
        <h3 className="HR_heading HR_MT" >
          Helpdesk 365 Plans
        </h3>
        <div className="HR_MT">
          <div>
            <Plans plans={planData} />
          </div>
        </div>
       
        <SpecailButtons />
        <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                </h3>
                <div className='Hr_flex HR_MT'>
                    <DemoButtons />
                </div>
            </div>
            <span className='privacytext'>*One Free Customization (upto 4 hours)</span>
            <div id="faq" className="IdChangesIntegration"></div>
            <div className='HR_FAQ'>

                <div >
                    <h4 className='HR_faq'>Frequently Asked Questions</h4>
                    <Accordion items={accordionItems} />
                </div>


            </div>
      <Footer /></>
  );
}
export default HD365;
